import axios from "axios";
require('dotenv').config()

console.log("URL", process.env.REACT_APP_API_URL)

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || "http://165.227.221.240:3030",
    params: {}
})


api.interceptors.request.use(config => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let { public_key } = params

    if (!public_key) {
        alert("PUBLIC KEY NÃO INFORMADA")
        return
    }

    config.params.public_key = public_key

    console.log(config)

    return config
})

api.interceptors.response.use(resp => {
    return resp
}, (error) => {
    throw error.response
})

export default api;
