import React, { useEffect, useContext } from "react"
import ChatContext from "../../contexts/ChatContext"

import WebChat from "../Webchat"

import Carregando from "../../components/Carregando"

import { getChat } from "../../services/chatService"
import ImageViewer from "../../components/ImageViewer"

export default function Orquestrador() {
    const { loadingChat, setLoadingChat, setIniciado, setSessionToken, setWebChatConfiguracoes, setMensagens, setChatUUID } = useContext(ChatContext)

    useEffect(() => {
        iniciar()
    }, [])

    const iniciar = async () => {
        try {
            let session_key = localStorage.getItem("session_key")

            console.log("session_key", session_key)

            let uuid = null
            let chat = null

            if (session_key && session_key != undefined) {
                setSessionToken(session_key)
                try {
                    chat = await getChat(session_key)
                    setWebChatConfiguracoes(chat.webchat)
                    if (chat.mensagens)
                        setMensagens(chat.mensagens)
                    uuid = chat.uuid
                } catch (error) {
                    if (error?.status == 404 && error?.data.webchat) {
                        let { webchat, online } = error.data
                        /*session_key = error.data.session_key
                        setSessionToken(session_key)
                        localStorage.setItem("session_key", session_key)*/
                        if (webchat) setWebChatConfiguracoes(webchat)
                        uuid = error?.data.uuid
                    } else {
                        throw error
                    }
                }
            } else {
                chat = await getChat()
                setWebChatConfiguracoes(chat.webchat)
                /*
                let sessao = await postIniciarChat()
                console.log("SESSAOOOO", sessao)
                let { session_key } = sessao
                uuid = sessao.uuid
                setSessionToken(session_key)
                localStorage.setItem("session_key", session_key)
                */
            }

            if (uuid) {
                setChatUUID(uuid)
            }

            console.log("CHAT", chat)
            if (chat?.auxiliar?.campos_iniciais) {
                setIniciado(true)
            }

            setLoadingChat(false)
        } catch (error) {
            console.error("ERRO NA INICIALIZAÇÃO DO CHAT", error)
        }
    }

    return (
        <>
            {loadingChat
                ? <Carregando />
                : <WebChat />
            }

            <ImageViewer />
        </>
    )
}