import React, { useContext, useRef } from "react"
import ChatContext from "../../contexts/ChatContext"
import Button from "../Button"

import Input from "../forms/Input"

import * as Yup from 'yup';

import "./styles.scss"

import { Form } from '@unform/web'

import { postIniciarChat } from "../../services/chatService"

import socket from "../../services/socketService"

export default function PreAtendimento() {
    const { webChatConfiguracoes, setLoadingChat, setIniciado, setSessionToken, setChatUUID, setMensagens, encerrarChat } = useContext(ChatContext)
    const formRef = useRef()

    const types = {
        cpf: "cpf"
    }

    const iniciar = async (campos_iniciais) => {
        try {
            setLoadingChat(true)

            let sessao = await postIniciarChat({ campos_iniciais })
            console.log("SESSAOOOO", sessao)
            let { session_key, uuid, mensagens } = sessao
            setChatUUID(uuid)
            setMensagens(mensagens)
            setSessionToken(session_key)
            localStorage.setItem("session_key", session_key)

            setIniciado(true)

            if (sessao.encerrado)
                encerrarChat()

            setLoadingChat(() => { return false })
        } catch (error) {
            setLoadingChat(false)
        }
    }

    const handleSubmit = async (data) => {
        console.log("handleSubmit", data)

        try {
            formRef.current.setErrors({})

            let shape = {}

            webChatConfiguracoes?.campos_iniciais.map(campo => {
                shape[campo.campo_contato ? `contato_${campo.campo_contato}` : `adicional_${campo.campo_adicional_id}`] = Yup.string().required("Campo obrigatório")
            })

            const schema = Yup.object().shape(shape)

            await schema.validate(data, { abortEarly: false })

            console.log("DATA", data)

            iniciar(data)
        } catch (err) {
            console.error(err)

            const validationErrors = {}

            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    console.error(error.path)
                    validationErrors[error.path] = error.message
                })
                console.log("validationErrors", validationErrors)
                formRef.current.setErrors(validationErrors)
            }
        }
    }

    return (
        <div className="preatendimento-container">
            <Form ref={formRef} onSubmit={handleSubmit}>
                {webChatConfiguracoes?.campos_iniciais.map(campo => (
                    <div key={campo.id}>
                        <Input
                            type={campo.validacao_layout || "text"}
                            name={campo.campo_contato ? `contato_${campo.campo_contato}` : `adicional_${campo.campo_adicional_id}`}
                            label={campo.campo_contato ? campo.campo_contato_nome : campo.campo_adicional_nome}
                            placeholder={campo.campo_contato ? campo.campo_contato_nome : campo.campo_adicional_nome}
                        />
                    </div>
                ))}

                {/*chatOnline == "offline" && <Input type="textarea" name="mensagem_offline" label="Mensagem" placeholder="Deixe uma mensagem para nossos atendentes verem depois" />*/}

                <Button style={{ marginTop: 20 }} onClick={() => formRef.current.submit}>Iniciar</Button>
            </Form>
        </div>
    )
}