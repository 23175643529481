import React, { useState, createContext, useEffect } from 'react'

import moment from "moment"

import socket from "../services/socketService"

const ChatContext = createContext({})

export const ChatProvider = ({ children }) => {
    const [loadingChat, setLoadingChat] = useState(true)

    const [iniciado, setIniciado] = useState(false)

    const [sessionToken, setSessionToken] = useState(null)
    const [webChatConfiguracoes, setWebChatConfiguracoes] = useState(null)

    const [mensagem, setMensagem] = useState("") // Que está sendo digitada no editor
    const [editorSubmit, setEditorSubmit] = useState(false)

    const [chatUUID, setChatUUID2] = useState(null)

    const [mensagens, setMensagens] = useState([])

    const [outroDigitando, setOutroDigitando] = useState(false)

    const [showEditor, setShowEditor] = useState(true)

    const encerrarChat = () => {
        if (showEditor) {
            setShowEditor(false)
            setMensagens((msgs) => [...msgs, {
                tipo: "notificacao",
                mensagem: "Atendimento finalizado",
                envio: false,
                datahora: moment()
            }])
        }
    }

    useEffect(() => {
        listener()
    }, [])

    useEffect(() => {
        console.log("ALTERAÇÃO MENSAGENS", mensagens)
        for (let msg of mensagens) {
            if (!msg.recebido && msg.status == 1) {
                console.log("Marcando mensagem como recebida", mensagem)
                socket.emit("mensagem_recebida", mensagem.id)
            }
        }
    }, [mensagens])

    const listener = () => {
        socket.on("mensagem", mensagem => {
            console.log("CHEGOU MENSAGEM NOVA", mensagem)
            if (mensagem.status == 1 && !mensagem.recebido) {
                socket.emit("mensagem_recebida", mensagem.id)
            }

            setMensagens((msgs) => {
                let mens = [...msgs]
                let achou = false

                for (let i = 0; i < msgs.length; i++) {
                    let msg = mens[i]
                    if ((mensagem.id_externo && msg.id_externo == mensagem.id_externo) || msg.id == mensagem.id) {
                        mens[i] = mensagem
                        achou = true
                        break
                    }
                }

                if (!achou) {
                    mens.push(mensagem)
                }

                return mens
            })
        })

        socket.on("digitando_server", digitando => {
            console.log("OUTRO DIGITANDO", digitando)
            setOutroDigitando(digitando)
        })

        socket.on("chat-finalizado", () => {
            encerrarChat()
        })
    }

    const setChatUUID = async (uuid) => {
        setChatUUID2(uuid)

        if (uuid) {
            socket.emit("join_room", `chat:${uuid}`)
            socket.on("connect", () => {
                socket.emit("join_room", `chat:${uuid}`)
            });
        }
    }

    return (
        <ChatContext.Provider value={{
            loadingChat, setLoadingChat,
            iniciado, setIniciado,
            sessionToken, setSessionToken,
            webChatConfiguracoes, setWebChatConfiguracoes,
            editorSubmit, setEditorSubmit,
            mensagem, setMensagem,
            mensagens, setMensagens,
            outroDigitando,
            chatUUID, setChatUUID,
            encerrarChat,
            showEditor
        }}>
            {children}
        </ChatContext.Provider>
    )
}

export default ChatContext
