import React, { useContext, useEffect, useRef } from "react"

import ChatContext from "../../contexts/ChatContext"

import Mensagem from "../Mensagem"

import "./styles.scss"
import "../../styles/animations.scss"

export default function ChatBody() {
    const { mensagens, outroDigitando } = useContext(ChatContext)

    const refRodapeMensagens = useRef()

    useEffect(() => {
        //window.scrollTo({ behavior: 'smooth', top: refRodapeMensagens.current.offsetTop })
        //console.log("REF", refRodapeMensagens.current.offsetTop)
    }, mensagens)

    return (
        <div className="chat-body-container">
            {mensagens.map((mensagem, index) => (
                <Mensagem key={index} mensagem={mensagem} />
            ))}
            {outroDigitando && <Mensagem mensagem={{ mensagem: `<p class="etc-loading"></p>`, envio: false, tipo: "carregando" }} className="outro-digitando" />}
            <div ref={refRodapeMensagens}></div>
        </div>
    )
}