import React, { useRef, useEffect } from "react"
import { useField } from '@unform/core'

import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import "./styles.scss"

export default function Input({ type, name, value, label, styleError, ...rest }) {
    const inputRef = useRef(null)
    const { fieldName, defaultValue, registerField, error } = useField(name)

    let masks = {
        cpf: "999.999.999-99",
        data: "99/99/9999"
    }

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.value
            },
            setValue: (ref, value) => {
                ref.current.value = value
            },
            clearValue: ref => {
                ref.current.value = ''
            },
        })
    }, [fieldName, registerField])

    return (
        <div className="input-container">
            <label className="label">{label}</label>
            {type && masks.hasOwnProperty(type) ?
                <ReactInputMask ref={inputRef} defaultValue={defaultValue} mask={masks[type]} maskChar="" {...rest} />
                : (
                    type == "textarea" ?
                        <textarea name={name} ref={inputRef} {...rest}>{defaultValue}</textarea>
                        :
                        <input name={name} ref={inputRef} {...rest} />
                )
            }

            {error &&
                <div className="error">
                    <p>{error}</p>
                </div>
            }
        </div>
    )
}