import React, { useContext } from "react"
import ChatContext from "../../contexts/ChatContext"

import "./styles.scss"

export default function ChatHeader() {
    const { webChatConfiguracoes } = useContext(ChatContext)
    return (
        <div className="chat-header-container" style={{ backgroundColor: webChatConfiguracoes?.webchat_cor_primaria, color: webChatConfiguracoes?.webchat_cor_secundaria }}>
            <div className="imagem-logo"></div>
            <div>
                <p className="titulo">{webChatConfiguracoes.webchat_titulo}</p>
                <p className="subtitulo">{webChatConfiguracoes.webchat_subtitulo}</p>
            </div>
        </div>
    )
}