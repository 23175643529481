import Orquestrador from './pages/Orquestrador';

import Providers from "./providers"

import "./App.css"

function App() {
  return (
    <Providers>
      <div className="container">
        <Orquestrador />
      </div>
    </Providers>
  );
}

export default App;
