import React, { useContext, useEffect } from "react"

import AppContext from "../../contexts/AppContext"

import "./styles.scss"

export default function ImageViewer() {
    const { imageViewerShow, setImageViewerShow } = useContext(AppContext)

    useEffect(() => {
        console.log("ImageViewer", imageViewerShow)
    }, [imageViewerShow])

    return (
        <>
            {imageViewerShow && <div className="image-viewer">
                <div className="image">
                    <a onClick={() => setImageViewerShow(null)}><i class="uil uil-times"></i></a>
                    <img src={imageViewerShow} />
                </div>
            </div>}
        </>
    )
}