import React, { useContext, useEffect, useState } from "react"
import ChatContext from "../../contexts/ChatContext"
import AppContext from "../../contexts/AppContext"

import { formatDiferencaDatas, formatDataHora } from "../../services/formatService"
import ImageViewer from "../ImageViewer"

import "./styles.scss"

export default function ({ mensagem }) {
    const { setImageViewerShow } = useContext(AppContext)
    const { webChatConfiguracoes, setMensagem, setEditorSubmit } = useContext(ChatContext)
    const [opened, setOpened] = useState(false)

    useEffect(() => {
        if (mensagem && mensagem.tipo == "menu") {
            var selector = `.menu-${mensagem.id}`
            document.querySelector(selector).addEventListener('click', (e) => {
                let entrada = e.target.value
                if (entrada != undefined) {
                    setMensagem(`${entrada}`)
                    setEditorSubmit(true)
                }
            });
        }
    }, [])

    return (
        <>
            {mensagem.tipo == "notificacao" ?
                <div className="mensagem-notificacao">
                    {mensagem.mensagem}
                    {mensagem.datahora && <p className="datahora">{`${formatDataHora(mensagem.datahora, "DD/MM/YYYY - HH:mm:ss")} (${formatDiferencaDatas(mensagem.datahora, null)})`}</p>}
                </div>
                :
                <div className={`chat-balao ${mensagem.envio ? "envio" : ""}`} style={webChatConfiguracoes ? { backgroundColor: webChatConfiguracoes[mensagem.envio ? "webchat_cor_mensagem_envio" : "webchat_cor_mensagem_recebida"] } : {}}>
                    {["imagem", "texto_imagem"].includes(mensagem.tipo) && (
                        <div className="imagens">
                            {mensagem.imagens.map(imagem => (
                                <>
                                    <div className="imagem">
                                        <img src={imagem.link || imagem.base64} />
                                        <div className="imagem-botoes">
                                            <button onClick={() => { setImageViewerShow(imagem.link || imagem.base64) }}><i class="uil uil-eye"></i></button>
                                            <button><i className="uil uil-download-alt"></i></button>
                                        </div>
                                    </div>
                                    {/*<ImageViewer opened={opened} setOpened={setOpened} imagem={mensagem.mensagem.split("/>")[0].replace(`<img src="`, "").split(`"`).join("").trim()} />*/}
                                </>
                            ))}
                        </div>
                    )}
                    {["arquivo", "texto_arquivo"].includes(mensagem.tipo) && (
                        <>
                            <div className="arquivos">
                                {mensagem.arquivos.map((arquivo, index) => (
                                    <div key={`arquivo-${index}`} className="arquivo" onClick={() => { if (arquivo.link) window.open(arquivo.link) }}>
                                        <div className="icone" style={(arquivo.tipo || arquivo.formato).split("/")[0] == "image" ? { backgroundImage: `url('${arquivo.base64 || arquivo.link}')` } : {}}>
                                            {(arquivo.tipo || arquivo.formato).split("/")[0] != "image" && <i className="uil uil-paperclip"></i>}
                                        </div>
                                        <div className="info">
                                            <p className="name">{arquivo.nome}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    {(["texto", "carregando"].includes(mensagem.tipo)) && <p className="mensagem" dangerouslySetInnerHTML={{ __html: mensagem.mensagem }}></p>}
                    {mensagem.tipo == "menu" && <>
                        <div className={`menu menu-${mensagem.id}`} dangerouslySetInnerHTML={{ __html: mensagem.mensagem }}></div>
                    </>}
                    {mensagem.datahora && <p className="datahora">{`${formatDataHora(mensagem.datahora, "DD/MM/YYYY - HH:mm:ss")} (${formatDiferencaDatas(mensagem.datahora, null)})`}</p>}
                    {mensagem.envio && <div className="status">
                        {mensagem.status == 0 && <i class="uil uil-clock"></i>}
                        {mensagem.status != 0 && <i class="uil uil-check"></i>}
                    </div>}
                </div>
            }
        </>
    )
}