import React, { useState, createContext } from 'react'

const AppContext = createContext({})

export const AppProvider = ({ children }) => {
    const [imageViewerShow, setImageViewerShow] = useState(null)

    return (
        <AppContext.Provider value={{
            imageViewerShow, setImageViewerShow
        }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext
