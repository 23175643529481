require('dotenv').config()
const io = require("socket.io-client");


const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

let { public_key } = params

if (!public_key) {
    alert("PUBLIC KEY NÃO INFORMADA")
}

console.log("URL", process.env.REACT_APP_API_URL)

const socket = io(`${process.env.REACT_APP_API_URL || "http://165.227.221.240:3030"}?public_key=${public_key}`);

export default socket;
