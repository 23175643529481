import api from "./apiService"

const getChat = async (session_key = null) => {
    try {
        const resultado = await api.get(`/chat/${session_key || ""}`)
        const chat = resultado.data
        return chat
    } catch (error) {
        throw error
    }
}


const postIniciarChat = async (data = {}) => {
    try {
        const resultado = await api.post("/chat/iniciar", data)
        const sessao = resultado.data
        return sessao
    } catch (error) {
        throw error
    }
}

const enviarMensagem = async (data) => {
    try {
        const resultado = await api.post("/chat/mensagem", data)
        const sessao = resultado.data
        return sessao
    } catch (error) {
        throw error
    }
}

export { getChat, postIniciarChat, enviarMensagem }