import React, { useState, useEffect, useContext, useRef } from "react"
import { v4 as uuidv4 } from 'uuid';

import Picker from 'emoji-picker-react';
import FileBase64 from 'react-file-base64';

import moment from "moment"

import ChatContext from "../../contexts/ChatContext"

import { enviarMensagem } from "../../services/chatService"

import socket from "../../services/socketService"

import "./styles.scss"

export default function Editor() {
    const { mensagens, setMensagens, webChatConfiguracoes, sessionToken, mensagem, setMensagem, editorSubmit, chatUUID } = useContext(ChatContext)
    const [showAtalhos, setShowAtalhos] = useState(false)

    const [chosenEmoji, setChosenEmoji] = useState(null);
    const [showEmoji, setShowEmoji] = useState(false)

    const [arquivos, setArquivos] = useState([])

    const [digitando, setDigitando] = useState(false)

    const fileRef = useState(null)

    // TEMPORÁRIO - INÍCIO

    const [atalhos] = useState([
        { nome: "Mensagem de boas-vindas", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla blabla blabla", atalho: "boas-vindas" },
        { nome: "Teste 1", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla", atalho: "teste1" },
        { nome: "Teste 2", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla blabla bla", atalho: "teste2" },
        { nome: "Teste 3", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla bla", atalho: "teste3" },
        { nome: "Teste 4", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla blabla bla", atalho: "teste4" },
        { nome: "Teste 5", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla", atalho: "teste5" },
    ])

    // TEMPORÁRIO - FIM

    useEffect(() => {
        console.log(mensagem)
        if (mensagem.trim().length > 0 && mensagem.trim()[0] == "/" && mensagem.split(" ").length == 1)
            setShowAtalhos(true)
        else
            setShowAtalhos(false)

        setDigitando(mensagem.trim().length > 0)
    }, [mensagem])

    useEffect(() => {
        socket.emit("digitando_cliente", {
            digitando: digitando,
            room: `chat:${chatUUID}`
        })
    }, [digitando])

    useEffect(() => {
        if (editorSubmit) {
            handleSubmit()
        }
    }, [editorSubmit])

    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(emojiObject);
        console.log(emojiObject)
        setMensagem(`${mensagem}${emojiObject.emoji}`)
    }

    const handleSubmit = async (e = null) => {
        if (e) e.preventDefault()
        console.log("handleSubmit", mensagem)

        if (mensagem.trim().length == 0 && arquivos.length == 0)
            return

        let tipo = "texto"
        if (arquivos.length > 0) {
            tipo = "arquivo"
            if (mensagem.trim().length > 0)
                tipo = "texto_arquivo"
        }

        let id_externo = uuidv4()

        let objMensagem = {
            id_externo: id_externo,
            mensagem: mensagem.trim(),
            envio: true,
            datahora: moment(),
            tipo: tipo,
            status: 0,
            session_key: sessionToken
        }

        let files = []
        for (let arquivo of arquivos) {
            let { base64, name: nome, type: tipo } = arquivo
            files.push({ base64, nome, tipo })
        }

        if (files.length > 0)
            objMensagem.arquivos = files

        console.log("MENSAGEM", objMensagem)

        setMensagens((msgs) => { return [...msgs, objMensagem] })

        setMensagem("")
        setArquivos([])

        try {
            let resultado = await enviarMensagem(objMensagem)

            /*for (let i = 0; i < newMensagens.length; i++) {
                let msg = newMensagens[i]
                if (msg.id_externo == resultado.id_externo) {
                    newMensagens[i] = resultado
                    break
                }
            }

            setMensagens(newMensagens)*/
        } catch (error) {
            setMensagens((mensagens) => {
                for (let i = 0; i < mensagens.length; i++) {
                    let msg = mensagens[i]
                    if (msg.id_externo == msg.id_externo) {
                        mensagens[i].status = 4
                        break
                    }
                }
                return mensagens
            })
        }
    }

    const onHandleSelectArquivos = (event) => {
        setArquivos(event)
        console.log(event)
    }

    const excluirArquivo = (arquivo) => {
        console.log("excluirArquivo", arquivo)
        setArquivos(arquivos.filter(a => a != arquivo))
    }

    return (
        <div className="editor-container">
            {showAtalhos && (
                <div className="atalhos-wrapper">
                    {atalhos.filter(a => a.atalho.indexOf(mensagem.replace("/", "")) >= 0).map((atalho, index) => (
                        <div className="atalho" key={index} onClick={() => {
                            setMensagem(atalho.mensagem)
                            setShowAtalhos(false)
                        }}>
                            <p className="atalho-nome">
                                {atalho.nome}
                                <p>/{atalho.atalho}</p>
                            </p>
                            <p className="atalho-mensagem">{atalho.mensagem}</p>
                        </div>
                    ))}
                </div>
            )}

            {arquivos?.length > 0 && <>
                <div className="arquivos-wrapper">
                    {arquivos.map((arquivo, index) => (
                        <div key={`arquivo-${index}`} className="arquivo">
                            <div className="icone" style={arquivo.type.split("/")[0] == "image" ? { backgroundImage: `url('${arquivo.base64}')` } : {}}>
                                {arquivo.type.split("/")[0] != "image" && <i className="uil uil-paperclip"></i>}
                            </div>
                            <div className="info">
                                <p className="name">{arquivo.name}</p>
                                <p className="size">{arquivo.size}</p>
                            </div>
                            <div className="excluir" onClick={() => { excluirArquivo(arquivo) }}>
                                <i className="uil uil-times"></i>
                            </div>
                        </div>
                    ))}
                </div>
            </>}

            <form onSubmit={handleSubmit}>
                {webChatConfiguracoes?.webchat_opcao_emoji == "1" && <div className="emoji-wrapper">
                    {/*showEmoji && <Picker onEmojiClick={onEmojiClick} pickerStyle={{ position: "absolute", bottom: 40 }} />*/}
                    <button className="button_icon" onClick={() => { setShowEmoji(!showEmoji) }}><i className="uil uil-emoji"></i></button>
                </div>}
                {webChatConfiguracoes?.webchat_opcao_anexo == "1" && <>
                    <button className="button_icon" onClick={() => { console.log(document.querySelector(".btn-anexo input").click()) }} className="btn-anexo">
                        <i className="uil uil-paperclip"></i>
                        <div style={{ display: "none" }}>
                            <FileBase64 multiple={true} onDone={onHandleSelectArquivos} ref={fileRef} />
                        </div>
                    </button>
                </>}

                <input type="text" value={mensagem} onChange={value => setMensagem(value.target.value)} placeholder={mensagens.length == 0 ? `Inicie a conversa...` : `Escreva aqui sua mensagem...`} style={showAtalhos ? { fontWeight: "bold" } : {}} />

                <button type="submit" className="button_icon" disabled={mensagem.trim().length == 0 && arquivos.length == 0}>
                    <i className="uil uil-message"></i>
                </button>
                {webChatConfiguracoes?.webchat_opcao_audio == "1" && <button className="button_icon"><i className="uil uil-microphone"></i></button>}

            </form>
        </div>
    )
}