import React from 'react'

import { ChatProvider } from '../contexts/ChatContext'
import { AppProvider } from '../contexts/AppContext'

const Providers = ({ children }) => {
    return (
        <AppProvider>
            <ChatProvider>
                {children}
            </ChatProvider>
        </AppProvider>
    )
}

export default Providers