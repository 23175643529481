import React, { useContext } from "react"

import ChatBody from "../../components/ChatBody";
import ChatHeader from "../../components/ChatHeader";
import Editor from "../../components/Editor";
import PreAtendimento from "../../components/PreAtendimento";
import ChatContext from "../../contexts/ChatContext";

import "./styles.scss"

export default function () {
    const { showEditor, iniciado } = useContext(ChatContext)

    return (
        <div className="container">
            <ChatHeader />
            {iniciado ? <>
                <ChatBody />
                {showEditor && <Editor />}
            </> : <PreAtendimento />}
        </div>
    )
}